import { Link } from "react-router-dom"
import Card from "../Card/Card"
import ImageComponent from "../ImageComponent/ImageComponent"
import "./Footer.css"
import TextComponent from "../TextComponent/TextComponent"
import {BsWhatsapp} from "react-icons/bs"
import {CgMail} from "react-icons/cg"
import {SlLocationPin,SlSocialVkontakte} from "react-icons/sl"
import ContactIconText from "../ContactIconText/ContactIconText"
import { useRecoilState } from "recoil"
import { menuFilterState } from "../Atom"

const Footer = () => {

    const [type, setType] = useRecoilState(menuFilterState)
    

    return (
        <footer className = "footer" >
            
            <Link to = "/" >
                <ImageComponent className = "footerLogo" src = "./Assets/Icons/alexaLogoWhite.png" />
            </Link>
            

            <Card className = "footerRow" >
                <Card className = "footerColumn" >
                    <Link className = "footerLink" to = "/" > Главная </Link>
                    <Link className = "footerLink" to = "/Facturing" > Производство </Link>
                    <Link className = "footerLink" to = "/Menu" onClick = { () => {
                        setType("all")
                        sessionStorage.setItem("menuFilterKey", "all")
                    }  }> Меню </Link>
                    <Link className = "footerLink" to = "/HoReCa" > HoReCa </Link>
                    <Link className = "footerLink" to = "/Contacts" > Контакты </Link>
                    <Link className = "footerLink" to = "/Delivery" > Условия и Доставка </Link>
                </Card>
                
                <Card className = "footerColumn" >
                    <p className = "footerColumnSubtitle" > Кондитерская </p>
                    <ContactIconText 
                        contactIcon = { <BsWhatsapp className="contactIconWhite" /> }
                        contactText = "+7 (905) 275-86-04"
                    />
                    <ContactIconText 
                        contactIcon = { <CgMail className="contactIconWhite" /> }
                        contactText = "alexa-ecleir@yandex.ru"
                    />
                    <ContactIconText 
                        contactIcon = { <SlLocationPin className="contactIconWhite" /> }
                        contactText = "Cанкт-Петербург Киевская ул., 28Б" 
                    />
                </Card>

                <Card className = "footerColumn" >
                    <p className = "footerColumnSubtitle" > Отдел HoReCa </p>
                    <ContactIconText 
                        contactIcon = { <BsWhatsapp className="contactIconWhite" /> }
                        contactText = "+7 (905) 204-23-07" 
                    />
                    <ContactIconText 
                        contactIcon = { <CgMail className="contactIconWhite" /> }
                        contactText = "alexa-slasti@yandex.ru" 
                    />
                    <ContactIconText 
                        contactIcon = { <SlSocialVkontakte className="contactIconWhite" /> }
                        contactText = "vk.slasti" 
                    />
                </Card>
            </Card>

        </footer>
    )
}

export default Footer