import Card from "../../Components/Card/Card"
import ComforterText from "../../Components/ComforterText/ComforterText"
import ContactIconTextDark from "../../Components/ContactIconTextDark/ContactIconTextDark"
import Footer from "../../Components/Footer/Footer"
import Header from "../../Components/Header/Header"
import ImageComponent from "../../Components/ImageComponent/ImageComponent"
import Navigation from "../../Components/Navigation/Navigation"
import Subtitle from "../../Components/Subtitle/Subtitle"
import TextComponent from "../../Components/TextComponent/TextComponent"
import Wrapper from "../../Components/Wrapper"
import ContactIconText from "../../Components/ContactIconText/ContactIconText"

import { PiGearBold } from "react-icons/pi"
import { LuCalendarCheck } from "react-icons/lu"
import { FaRegHandshake, FaHandHoldingUsd } from "react-icons/fa"
import { BsWhatsapp } from "react-icons/bs"
import { CgMail } from "react-icons/cg"

import "./Delivery.css"

const Delivery = () => {

    /////////////////////////////////////////
    window.scrollTo(0, 0)
    ////////////////////////////////////////

    ///////// scroll bar on ///////////////
    document.body.style.overflowY = "auto"
    //////////////////////////////////////

    return (
        <Wrapper>

            <Navigation />

            <Header
                bgImage="./Assets/Images/deliveryPageHeader.WEBP"
                headerText="Кондитерская Алекса"
                headerTitle="Сотрудничество и доставка"
                headerLink="Связаться с нами"
                headerTo="/Contacts"
            />

            <Card className="deliveryMain" >
                <Card className="deliveryMainColumn" >
                    <ComforterText> Кондитерская Алекса </ComforterText>
                    <Subtitle> Доставка по СПб </Subtitle>
                    <TextComponent> Каждый день наши курьеры выполняют доставку заказов в Санкт-Петербурге, за исключением воскресенья. Более того, доставка осуществляется <strong> абсолютно бесплатно! </strong> </TextComponent>
                    <TextComponent> Важно учесть, что доставка осуществляется по предварительному заказу. У вас есть возможность выбрать удобную дату доставки или мы привезем Ваш заказ на следующий день. </TextComponent>
                </Card>
                <Card className="deliveryMainColumn" >
                    <ImageComponent src="./Assets/Images/deliveryPageImage1.WEBP" />
                </Card>
            </Card>

            <Card className="deliveryMain" >
                <Card className="deliveryMainColumn" >
                    <ImageComponent src="./Assets/Images/deliveryPageImage2.WEBP" />
                </Card>
                <Card className="deliveryMainColumn" >
                    <ComforterText> Сотрудничество </ComforterText>
                    <Subtitle> Приглашаем к сотрудничеству </Subtitle>
                    <TextComponent> Если у Вас бизнес в сфере общепита, гостиничного сервиса или розничной торговли, мы будем рады видеть Вас в числе наших партнеров по реализации. </TextComponent>
                    <TextComponent> Взаимовыгодное и честное сотрудничество являются ключевыми принципами работы, поскольку наша компания стремится к развитию долгосрочных отношений. </TextComponent>
                    <Card className="cooperationColumn" >
                        <ContactIconTextDark
                            contactIcon={<PiGearBold className="contactIconWhite" />}
                            contactText="Контроль качества выпускаемой продукции"
                        />
                        <ContactIconTextDark
                            contactIcon={<LuCalendarCheck className="contactIconWhite" />}
                            contactText="Соблюдение сроков и объемов поставок"
                        />
                        <ContactIconTextDark
                            contactIcon={<FaRegHandshake className="contactIconWhite" />}
                            contactText="Соблюдение условий договорных отношений"
                        />
                        <ContactIconTextDark
                            contactIcon={<FaHandHoldingUsd className="contactIconWhite" />}
                            contactText="Контроль над системой ценообразования"
                        />
                    </Card>
                </Card>
            </Card>

            <Card>
                <Card className="cooperationColumnPadding" >
                    <p className="whiteSubtitle" > По вопросам сотрудничества Вы можете обратиться: </p>
                    <ContactIconText
                        contactIcon={< BsWhatsapp className="contactIconWhite" />}
                        contactText="+7 (905) 275-86-04"
                    />
                    <ContactIconText
                        contactIcon={< CgMail className="contactIconWhite" />}
                        contactText="alexa-ecleir@yandex.ru"
                    />
                    <p className="whiteText" > Если у Вас есть интересные проекты и предложения направляйте на нашу почту информацию с пометкой <strong> «Для отдела маркетинга» </strong>, мы с удовольствием их рассмотрим и наш представитель свяжется с Вами для обсуждения деталей. </p>
                    <p className="whiteText" > А если Вы хотите сделать особенное предложение, то пишите на почту с отметкой должности, для которого у Вас важная информация (Генеральный директор, Главный кондитер, Технолог, Начальник логистической службы). </p>
                </Card>
            </Card>

            <Footer />

        </Wrapper>
    )
}

export default Delivery