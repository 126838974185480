import Card from "../../Components/Card/Card"
import Footer from "../../Components/Footer/Footer"
import Header from "../../Components/Header/Header"
import ImageComponent from "../../Components/ImageComponent/ImageComponent"
import Navigation from "../../Components/Navigation/Navigation"
import TextComponent from "../../Components/TextComponent/TextComponent"
import Wrapper from "../../Components/Wrapper"
import ComforterText from "../../Components/ComforterText/ComforterText"
import Subtitle from "../../Components/Subtitle/Subtitle"
import ContactIconTextDark from "../../Components/ContactIconTextDark/ContactIconTextDark"

import { BsWhatsapp } from "react-icons/bs"
import { CgMail } from "react-icons/cg"

import "./Facturing.css"


import { useRecoilState } from "recoil"
import { menuFilterState } from "../../Components/Atom"


const Facturing = () => {

    /////////////////////////////////////////
    window.scrollTo(0, 0)
    ////////////////////////////////////////

    ///////// scroll bar on ///////////////
    document.body.style.overflowY = "auto"
    //////////////////////////////////////

    const [type, setType] = useRecoilState(menuFilterState)



    return (
        <Wrapper>

            <Navigation />

            <Header
                bgImage="./Assets/Images/facturingPageHeader.WEBP"
                headerText="100% натуральные ингредиенты"
                headerTitle="Производство Алекса"
                headerLink="Смотреть меню"
                headerTo="/Menu"

                onClick={() => {
                    setType("all")
                    sessionStorage.setItem("menuFilterKey", "all")
                }}
            />

            <Card className="facturingPageMain" >
                <Card className="facturingPageMinColumn">
                    <ComforterText> О нашей кондитерской </ComforterText>
                    <Subtitle> Производство "Алекса" </Subtitle>
                    <TextComponent className="homePageMainColumText" > Наше кондитерское производство "Алекса" – это результат долгой и тщательной работы наших специалистов, которые преданы своему делу и стремятся достичь совершенства в каждом лакомстве. Мы гордимся тем, что все наши десерты создаются исключительно вручную. </TextComponent>
                    <TextComponent className="homePageMainColumText" > В нашем производстве мы используем только самые качественные ингредиенты, которые проходят строгий отбор, чтобы гарантировать вам исключительно эстетические и вкусовые ощущения. Каждый десерт из "Алекса" – это неповторимый кулинарный шедевр, которым мы хотим порадовать вас. </TextComponent>
                    <TextComponent className="homePageMainColumText" > Мы верим, что еда может быть не только вкусной, но и искусством, способным подарить незабываемые эмоции. Мы готовы делиться этим искусством с вами и быть частью вашей радости и наслаждения. </TextComponent>
                    <TextComponent className="homePageMainColumText" > Выбирая продукцию "Алекса", вы можете быть уверены в ее высочайшем качестве и неповторимом вкусе. </TextComponent>
                </Card>
                <Card className="facturingPageMinColumn">
                    <ImageComponent src="./Assets/Images/facturingPageImage1.WEBP" />
                </Card>
            </Card>

            <Card className="facturingPageMain" >
                <Card className="facturingPageMinColumn" >
                    <ImageComponent src="./Assets/Images/facturingPageImage2.WEBP" />
                </Card>
                <Card className="facturingPageMinColumn" >
                    <ComforterText> Контактная информация </ComforterText>
                    <Subtitle> Связаться с нами </Subtitle>
                    <TextComponent className="homePageMainColumText" > Мы специализируемся на исполнении кондитерской мечты любой сложности! Чтобы узнать больше о возможностях и услугах, которые мы предлагаем, а также получить профессиональную консультацию от наших мастеров, свяжитесь с нами! </TextComponent>

                    <Card className="facturingPageContactsRow" >
                        <Card className="facturingPageContactsColumn" >
                            <p> Кондитерская </p>
                            <ContactIconTextDark
                                contactIcon={<BsWhatsapp className="contactIconWhite" />}
                                contactText="+7 (905) 275-86-04"
                            />
                            <ContactIconTextDark
                                contactIcon={<CgMail className="contactIconWhite" />}
                                contactText="alexa-ecleir@yandex.ru"
                            />
                        </Card>
                        <Card className="facturingPageContactsColumn" >
                            <p> Отдел HoReCa </p>
                            <ContactIconTextDark
                                contactIcon={<BsWhatsapp className="contactIconWhite" />}
                                contactText="+7 (905) 204-23-07"
                            />
                            <ContactIconTextDark
                                contactIcon={<CgMail className="contactIconWhite" />}
                                contactText="alexa-slasti@yandex.ru"
                            />
                        </Card>
                    </Card>
                </Card>
            </Card>

            <Footer />

        </Wrapper>
    )
}

export default Facturing