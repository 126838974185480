import Card from "../../Components/Card/Card"
import Header from "../../Components/Header/Header"
import Navigation from "../../Components/Navigation/Navigation"
import TextComponent from "../../Components/TextComponent/TextComponent"
import Wrapper from "../../Components/Wrapper"
import Footer from "../../Components/Footer/Footer"
import ComforterText from "../../Components/ComforterText/ComforterText"
import Subtitle from "../../Components/Subtitle/Subtitle"
import ViewMenuButton from "../../Components/ViewMenuButton/ViewMenuButton"


import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";


import "./Home.css"

import { useRecoilState } from "recoil"
import { menuFilterState } from "../../Components/Atom"


const Home = () => {

    /////////////////////////////////////////
    window.scrollTo(0, 0)
    ////////////////////////////////////////

    ///////// scroll bar on ///////////////
    document.body.style.overflowY = "auto"
    //////////////////////////////////////

    const [type, setType] = useRecoilState(menuFilterState)

    return (
        <Wrapper>

            <Navigation />

            <Header
                bgImage="./Assets/Images/homePageHeader.WEBP"
                headerText="100% натуральные ингредиенты"
                headerTitle="Кондитерская Алекса"
                headerLink="Смотреть меню"
                headerTo="/Menu"

                onClick={() => {
                    setType("all")
                    sessionStorage.setItem("menuFilterKey", "all")
                }}
            />

            <Card className="homePageMain" >

                <Card className="homePageMainColumn" >
                    <ComforterText> О нашей кондитерской </ComforterText>
                    <Subtitle> Кондитерская "Алекса" </Subtitle>
                    <TextComponent> "Алекса" - это кондитерское производство, начавшая свою деятельность в апреле 2018 года и с тех пор завоевала сердца ценителей высококачественных сладостей. Наша компания специализируется на создании десертов ручной работы, обладающих неповторимым вкусом и безукоризненным качеством. </TextComponent>
                    <TextComponent> Появление "Алексы" на рынке кондитерских изделий произошло в начале 2018 года, когда наша команда решила превратить свою любовь к кондитерскому искусству в реальность. Мы открыли собственное производство и приступили к созданию десертов, которые радуют глаз и удовлетворяют самые изысканные вкусовые предпочтения наших клиентов. </TextComponent>
                    <ViewMenuButton
                        onClick={() => {
                            setType("all")
                            sessionStorage.setItem("menuFilterKey", "all")
                        }}
                    > Смотреть меню </ViewMenuButton>
                </Card>

                <Card className="homePageMainColumn" >
                    <Carousel showArrows={true} >
                        <div>
                            <img src="./Assets/Images/slider1.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider2.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider3.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider4.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider5.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider6.WEBP" />
                        </div>
                    </Carousel>
                </Card>

            </Card>

            <Footer />

        </Wrapper>
    )
}

export default Home