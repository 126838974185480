import { Link } from "react-router-dom"
import "./ViewMenuButton.css"

const ViewMenuButton = (props) => {
    return (
        <Link onClick = {props.onClick} to = "/Menu" className="viewMenuButton">
            {props.children}
        </Link>
    )
}

export default ViewMenuButton