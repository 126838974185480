import Card from "../../Components/Card/Card"
import Footer from "../../Components/Footer/Footer"
import Navigation from "../../Components/Navigation/Navigation"
import Wrapper from "../../Components/Wrapper"
import MenuItem from "../../Components/MenuItem/MenuItem"


import "./Menu.css"


import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { bagItemsState, menuFilterState, shopCardItemsCountState } from "../../Components/Atom"


const Menu = () => {

    ///////// scroll bar on ///////////////
    document.body.style.overflowY = "auto"
    //////////////////////////////////////

    const port = "https://alexasweets.ru/public/"
    if (sessionStorage.getItem("menuFilterKey") === null) {
        sessionStorage.setItem("menuFilterKey", "all")
    }
    // console.log(sessionStorage.getItem("menuFilterKey"));
    const [type, setType] = useRecoilState(menuFilterState)
    const [menuItems, setMenuItems] = useState([])
    const menuItemsFetch = () => {
        {
            fetch(`https://alexasweets.ru/api/products/${type}`)
                .then(response => response.json())
                .then(result => {
                    result.map(el => {
                        el.currentWeight = el.weight
                        el.currentPrice = el.price
                        el.checked = "1"
                        // el.count = 0
                        setMenuItems((prevState) => [...prevState, el])
                    })
                })
        }
    }

    if (sessionStorage.getItem("shopCardItemsCount") === null) {
        sessionStorage.setItem("shopCardItemsCount", 0)
    }
    const [shopCardItemsCount, setShopCardItemsCount] = useRecoilState(shopCardItemsCountState)

    if (sessionStorage.getItem("bagItems") === null) {
        sessionStorage.setItem("bagItems", JSON.stringify([]))
    }
    const pageBagItems = JSON.parse(sessionStorage.getItem("bagItems"))
    const [bagItems, setBagItems] = useState([...pageBagItems])
    // const [bagItems,setBagItems] = useRecoilState(bagItemsState)

    if (sessionStorage.getItem("bagIds") === null) {
        sessionStorage.setItem("bagIds", JSON.stringify([]))
    }
    const pageBagIds = JSON.parse(sessionStorage.getItem("bagIds"))
    const [bagIds, setBagIds] = useState([...pageBagIds])

    useEffect(() => {
        setMenuItems([])
        menuItemsFetch()
    }, [type])





    return (
        <Wrapper>

            <Navigation />

            <div className="menuFiltersCard">
                <button
                    className={sessionStorage.getItem("menuFilterKey") === "all" ? "active" : null + " menuFilterButton"}
                    onClick={(event) => {
                        setType("all")
                        sessionStorage.setItem("menuFilterKey", "all")
                    }}
                > Все </button>

                <button
                    className={sessionStorage.getItem("menuFilterKey") === "cakes" ? "active" : null + " menuFilterButton"}
                    onClick={() => {
                        setType("cakes")
                        sessionStorage.setItem("menuFilterKey", "cakes")
                    }}
                > Пирожные </button>

                <button
                    className={sessionStorage.getItem("menuFilterKey") === "cookie" ? "active" : null + " menuFilterButton"}
                    onClick={() => {
                        setType("cookie")
                        sessionStorage.setItem("menuFilterKey", "cookie")
                    }}
                > Печенье </button>

                <button
                    className={sessionStorage.getItem("menuFilterKey") === "puff" ? "active" : null + " menuFilterButton"}
                    onClick={() => {
                        setType("puff")
                        sessionStorage.setItem("menuFilterKey", "puff")
                    }}
                > Слоеные изделия </button>

                <button
                    className={sessionStorage.getItem("menuFilterKey") === "torts" ? "active" : null + " menuFilterButton"}
                    onClick={() => {
                        setType("torts")
                        sessionStorage.setItem("menuFilterKey", "torts")
                    }}
                > Торты </button>
            </div>

            <div className="menuMain" >
                {
                    menuItems.map(menuItem => {

                        return <MenuItem
                            key={menuItem.id}

                            // src = {port + menuItem.image }
                            src={port + menuItem.image}

                            onMouseEnter={(event) => {
                                if (menuItem.image_2) {
                                    if (event.target.className === "menuItemImage") {
                                        event.target.src = port + menuItem.image_2
                                    }
                                    else if (event.target.className === "menuItem") {
                                        event.target.firstChild.src = port + menuItem.image_2
                                    }
                                    else if (event.target.className === "menuItemInfoCard") {
                                        event.target.parentElement.firstChild.src = port + menuItem.image_2
                                    }
                                    else if (event.target.className === "menuItemName" || event.target.className === "discription" || event.target.className === "menuItemWeight" || event.target.className === "menuItemPriceCaard" || event.target.className === "sizesButtons") {
                                        event.target.parentElement.parentElement.firstChild.src = port + menuItem.image_2
                                    } else if (event.target.className === "menuItemPrice" || event.target.className === "addToCardButton" || event.target.className === "activeSize" || event.target.className === "noClass") {
                                        event.target.parentElement.parentElement.parentElement.firstChild.src = port + menuItem.image_2
                                    }
                                }
                            }}

                            onMouseLeave={(event) => {
                                if (menuItem.image_2) {
                                    if (event.target.className === "menuItemImage") {
                                        event.target.src = port + menuItem.image
                                    }
                                    else if (event.target.className === "menuItem") {
                                        event.target.firstChild.src = port + menuItem.image
                                    }
                                    else if (event.target.className === "menuItemInfoCard") {
                                        event.target.parentElement.firstChild.src = port + menuItem.image
                                    }
                                    else if (event.target.className === "menuItemName" || event.target.className === "discription" || event.target.className === "menuItemWeight" || event.target.className === "menuItemPriceCaard" || event.target.className === "sizesButtons") {
                                        event.target.parentElement.parentElement.firstChild.src = port + menuItem.image
                                    } else if (event.target.className === "menuItemPrice" || event.target.className === "addToCardButton" || event.target.className === "activeSize" || event.target.className === "noClass") {
                                        event.target.parentElement.parentElement.parentElement.firstChild.src = port + menuItem.image
                                    }
                                }
                            }}

                            menuItemName={menuItem.name}
                            menuItemWeight={menuItem.currentWeight}
                            menuItemPrice={menuItem.currentPrice}
                            title={menuItem.description}
                            menuItemDescription={menuItem.description}

                            onClick={() => {

                                let pageMenuItems = [...menuItems]

                                pageMenuItems.map(el => {

                                    if (el.id === menuItem.id && !pageBagIds.includes(el.id)) {
                                        el.count = 1
                                        setShopCardItemsCount(shopCardItemsCount + 1)
                                        sessionStorage.setItem("shopCardItemsCount", +sessionStorage.getItem("shopCardItemsCount") + 1)
                                        setMenuItems([...pageMenuItems])




                                        if (!pageBagIds.includes(el.id)) {

                                            pageBagIds.push(el.id)
                                            setBagIds([...pageBagIds])
                                            sessionStorage.setItem("bagIds", JSON.stringify(pageBagIds))

                                            pageBagItems.push(el)
                                            setBagItems([...pageBagItems])
                                            sessionStorage.setItem("bagItems", JSON.stringify(pageBagItems))
                                        }
                                    }


                                })

                            }}
                        >
                            {
                                menuItem.weight_2 && menuItem.price_2 ?
                                    <div className="sizesButtons">
                                        <button className={menuItem.checked === "1" ? "activeSize activeSizeFull" : "noClass"} onClick={() => {
                                            const pageMenuItems = [...menuItems]

                                            pageMenuItems.map(el => {
                                                if (el.id === menuItem.id) {

                                                    el.currentWeight = el.weight
                                                    el.currentPrice = el.price
                                                    el.checked = "1"

                                                    setMenuItems([...pageMenuItems])

                                                }
                                            })
                                        }}> 14 см. </button>

                                        <button className={menuItem.checked === "2" ? "activeSize activeSizeFull" : "noClass"} onClick={() => {
                                            const pageMenuItems = [...menuItems]


                                            pageMenuItems.map(el => {
                                                if (el.id === menuItem.id) {

                                                    el.currentWeight = el.weight_2
                                                    el.currentPrice = el.price_2
                                                    el.checked = "2"

                                                    setMenuItems([...pageMenuItems])

                                                }
                                            })

                                        }}> 18 см. </button>
                                    </div>
                                    : <div className="noneDiv"></div>
                            }



                        </MenuItem>
                    })
                }
            </div>

            <Footer />
        </Wrapper>
    )
}

export default Menu