import Card from "../../Components/Card/Card"
import Footer from "../../Components/Footer/Footer"
import Navigation from "../../Components/Navigation/Navigation"
import Wrapper from "../../Components/Wrapper"
import TextComponent from "../../Components/TextComponent/TextComponent"

import "./Contacts.css"
import Subtitle from "../../Components/Subtitle/Subtitle"

const Contacts = () => {

    ///////////////////////////////////
    window.scrollTo(0,0)
    ///////////////////////////////////

    ///////// scroll bar on ///////////////
    document.body.style.overflowY = "auto"
    //////////////////////////////////////

    return (
        <Wrapper>

            <Navigation />

            <Card className="adressPageMin">

                <Card className="adressPageColumn">
                    {/* <TextComponent className="adressPageTitle"> Адрес Кондитерского Алекса </TextComponent> */}
                    <Subtitle> Адрес Кондитерского Алекса </Subtitle>
                    <p className="adressPageAdressText"> Санкт-Петербург Киевская ул., 28Б </p>
                    <p className="adressPageText"> Часы работы: 10:00 – 13:00/14:00 – 19:30 </p>
                    <p className="adressPageText"> Суббота: Выходной </p>
                    <p className="adressPageText"> Воскресенье: Выходной </p>

                    <p className="adressPageSubTitle"> Контактная информация: </p>
                    <p className="adressPageText"> Мы специализируемся на исполнении кондитерской мечты любой сложности! Для индивидуальных заказов и консультации, свяжитесь с нами </p>

                    <p className="adressPageSubTitle"> Телефон и электронный адрес кондитерского </p>
                    <p className="adressContactsTelNumberEmail"> +7 (905) 275-86-04 </p>
                    <p className="adressContactsTelNumberEmail"> alexa-ecleir@yandex.ru </p>
                    <p className="adressPageSubTitle"> Телефон и электронный адрес HoReCa </p>
                    <p className="adressContactsTelNumberEmail"> +7 (905) 204-23-07 </p>
                    <p className="adressContactsTelNumberEmail"> alexa-slasti@yandex.ru </p>
                </Card>

                <Card className="adressPageColumn">
                    <div className="adressPageMap" style={ {position: "relative",overflow: "hidden"} }>
                        <a href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps" style={ { color: "#eee", fontSize: 12 + "px", position: "absolute", top: 0 + "px" } }>Санкт‑Петербург</a>
                        <a href="https://yandex.ru/maps/2/saint-petersburg/house/kiyevskaya_ulitsa_28b/Z0kYdQRgTEQGQFtjfXVxdXxqZA==/?ll=30.331898%2C59.903986&utm_medium=mapframe&utm_source=maps&z=17.22" style={ { color: "#eee", fontSize: 12 + "px", position: "absolute", top: 14 + "px" } }>Киевская улица, 28Б — Яндекс Карты</a>
                        <iframe src="https://yandex.ru/map-widget/v1/?ll=30.331898%2C59.903986&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNjIwNjcxNjk5Ek7QoNC-0YHRgdC40Y8sINCh0LDQvdC60YIt0J_QtdGC0LXRgNCx0YPRgNCzLCDQmtC40LXQstGB0LrQsNGPINGD0LvQuNGG0LAsIDI40JEiCg3ipPJBFcqdb0I%2C&z=17.22" width="100%" height="600px" frameBorder="1" allowFullScreen={true} style={ { position: "relative" } }></iframe>
                    </div>
                </Card>

            </Card>

            <Footer />
        </Wrapper>
    )
}

export default Contacts