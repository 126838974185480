import Home from "./Ui/Home/Home"
import Facturing from "./Ui/Facturing/Facturing"
import Menu from "./Ui/Menu/Menu"
import HoReCa from "./Ui/HoReCa/HoReCa"
import Contacts from "./Ui/Contacts/Contacts"
import Delivery from "./Ui/Delivery/Delivery"
import ShopCard from "./Ui/ShopCard/ShopCard"


import { Route, Routes } from "react-router-dom"


import "./App.css"


import { RecoilRoot } from "recoil"


const App = () => {
    return (

        <RecoilRoot>
            
            <Routes>
                <Route path="/" element = { <Home /> } />
                <Route path="/Facturing" element = { <Facturing /> } />
                <Route path="/Menu" element = { <Menu /> } />
                <Route path="/HoReCa" element = { <HoReCa /> } />
                <Route path="/Contacts" element = { <Contacts /> } />
                <Route path="/Delivery" element = { <Delivery /> } />
                <Route path="/ShopCard" element = { <ShopCard /> } />
            </Routes>
            
        </RecoilRoot>

    )
}

export default App