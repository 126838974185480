import { atom } from "recoil";

export const menuFilterState = atom({
    key: "menuFilterKey",
    default: sessionStorage.getItem("menuFilterKey") || "all",
})

export const shopCardItemsCountState = atom({
    key: "shopCardItemsCount",
    default: +sessionStorage.getItem("shopCardItemsCount") || 0,
})

