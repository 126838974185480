import { useEffect, useState } from "react"


import Card from "../../Components/Card/Card"
import Footer from "../../Components/Footer/Footer"
import Navigation from "../../Components/Navigation/Navigation"
import Wrapper from "../../Components/Wrapper"


import { useRef } from "react"
import emailjs from "@emailjs/browser"


import { MdDeleteForever } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";


import { Link } from "react-router-dom"


import { useRecoilState } from "recoil"
import { shopCardItemsCountState } from "../../Components/Atom"


import "./ShopCard.css"

const ShopCard = () => {

    /////////////////////////////////////////
    // window.scrollTo(0, 0)
    ////////////////////////////////////////

    const form = useRef()
    const sendEmail = (event) => {
        event.preventDefault()
        emailjs.sendForm("service_s2ntop9", "template_12uxtxj", form.current, "X0mrPwRd_o4yL3u_k",)
    }

    const port = "https://alexasweets.ru/public/"
    if (sessionStorage.getItem("bagItems") === null) {
        sessionStorage.setItem("bagItems", JSON.stringify([]))
    }
    const [shopCardItems, setShopCardItems] = useState(JSON.parse(sessionStorage.getItem("bagItems")))

    if (sessionStorage.getItem("bagIds") === null) {
        sessionStorage.setItem("bagIds", JSON.stringify([]))
    }
    const [bagIds, setBagIds] = useState(JSON.parse(sessionStorage.getItem("bagIds")))

    if (sessionStorage.getItem("shopCardItemsCount") === null) {
        sessionStorage.setItem("shopCardItemsCount", 0)
    }
    const [shopCardItemsCount, setShopCardItemsCount] = useRecoilState(shopCardItemsCountState)

    const [check, setCheck] = useState(0)

    const [tableCheck, setTableCheck] = useState(0)

    const [table, setTable] = useState("")
    let message = `
        <table border="2">
            <thead>
                <th>имя</th>
                <th>количество</th>
                <th>цена</th>
                <th> полная стоимость</th>
            </thead>
            <tbody>
                ${shopCardItems.map(el => {

        return (
            `<tr>
                                <td> ${el.name} </td>
                                <td> ${el.count} </td>
                                <td> ${el.price} </td>
                                <td> ${el.count * el.price} </td>
                            </tr>`
        )
    })
        }
            </tbody>
            <tfoot>
                    <tr>
                        <td colspan="2"> полная цена </td>
                        <td> ${check} </td>
                    </tr>
            </tfoot>
        </table>
    `
    let result = ""

    for (let i = 0; i < message.length; i++) {
        if (message[i] !== ",") {
            result = result + message[i]
        }
    }


    const checkFunction = () => {
        let x = 0
        shopCardItems.map(el => {
            x = x + (el.count * el.currentPrice)
        })
        setCheck(x)
    }

    const [formScreenBlocker, setFormScreenBlocker] = useState("none")
    const [success, setSuccess] = useState("none")

    useEffect(() => {
        setTable(result)
        checkFunction()
    }, [shopCardItems, check, tableCheck])



    if (shopCardItemsCount === 0) {
        return (
            <Wrapper>
                <Navigation />
                <div className="emptyCard">
                    <AiOutlineShoppingCart className="emptyCardIcon" />
                    <h1 className="emptyCardTitle" > Ваша корзина пуста </h1>
                    <p className="emptyCardText" > Для покупок вернитесь в раздел <Link className="emptyCardLink" to="/Menu"> Меню </Link> </p>
                </div>
                <Footer />
            </Wrapper>
        )
    }
    else {
        return (

            <Wrapper>

                <Navigation />



                <Card className="shopCardMain" >
                    <Card className="shopCardMainColumn">
                        {
                            shopCardItems.map(shopCardItem => {
                                return (
                                    <div key={shopCardItem.id} className="shopCardItem" >
                                        <img src={port + shopCardItem.image} alt="productImage" className="shopCardProductImage" />

                                        <div className="shopCardItemInfo" >
                                            <p className="shopCardItemName" > {shopCardItem.name} </p>
                                            <p className="shopCardItemWeight"> {shopCardItem.currentWeight} Гр. </p>
                                            <p className="shopCardItemPrice" > {shopCardItem.currentPrice} Руб. </p>
                                        </div>

                                        <div className="shopCardItemCounterCard">

                                            <button
                                                className={shopCardItem.count === 1 ? "disabledMinusButton" : "shopCardItemCounterButton"}
                                                disabled={shopCardItem.count === 1 ? true : false}
                                                onClick={() => {

                                                    let pageShopCardItems = [...shopCardItems]

                                                    pageShopCardItems.map(el => {
                                                        if (el.id === shopCardItem.id) {
                                                            el.count--
                                                        }
                                                        setShopCardItems([...pageShopCardItems])
                                                        sessionStorage.setItem("bagItems", JSON.stringify([...pageShopCardItems]))
                                                    })

                                                }}
                                            > - </button>

                                            <p className="shopCardItemCount" > {shopCardItem.count} </p>

                                            <button
                                                className="shopCardItemCounterButton"
                                                onClick={() => {

                                                    let pageShopCardItems = [...shopCardItems]

                                                    pageShopCardItems.map(el => {
                                                        if (el.id === shopCardItem.id) {
                                                            el.count++
                                                        }
                                                        setShopCardItems([...pageShopCardItems])
                                                        sessionStorage.setItem("bagItems", JSON.stringify([...pageShopCardItems]))
                                                    })

                                                }}
                                            > + </button>

                                        </div>

                                        <div className="fullWeightPrice">
                                            <p> {shopCardItem.currentWeight * shopCardItem.count} Гр. </p>
                                            <p> {shopCardItem.currentPrice * shopCardItem.count} Руб. </p>
                                        </div>

                                        <button
                                            className="deletButton"
                                            onClick={() => {
                                                let shopCardPageItems = [...shopCardItems]
                                                let pageBagIds = [...bagIds]

                                                shopCardPageItems = shopCardPageItems.filter(el => el.id !== shopCardItem.id)
                                                setShopCardItems([...shopCardPageItems])
                                                sessionStorage.setItem("bagItems", JSON.stringify(shopCardPageItems))



                                                pageBagIds = pageBagIds.filter(el => el !== shopCardItem.id)
                                                setBagIds([...pageBagIds])
                                                sessionStorage.setItem("bagIds", JSON.stringify(pageBagIds))

                                                setShopCardItemsCount(shopCardItemsCount - 1)
                                                sessionStorage.setItem("shopCardItemsCount", +sessionStorage.getItem("shopCardItemsCount") - 1)


                                            }}
                                        >
                                            <MdDeleteForever className="deleteButtonIcon" />
                                        </button>

                                    </div>
                                )
                            })
                        }
                    </Card>

                    <Card className="shopCardMainColumn" >
                        <div className="orderCheck">
                            <img src="./Assets/Icons/alexaLogo.png" alt="logo" />
                            <h2 className="orderCheckTitle"> Вы заказали продукты </h2>
                            <p className="orderCheckPriceText"> При покупке от 8000 рублей - бонус одна упаковка нашей продукции </p>
                            <p className="orderCheckPriceText">
                                Стоимость {check} Руб.
                            </p>
                            <button className="orderCheckButton" onClick={() => {
                                setFormScreenBlocker("formScreenBlocker")
                                window.scrollTo(0, 0);
                                document.body.style.overflowY = "hidden"

                                checkFunction()
                                setTableCheck(check)
                            }}> Заказать </button>
                        </div>
                    </Card>
                </Card>




                <div className={formScreenBlocker}>

                    <form ref={form} className="orderForm" onSubmit={sendEmail}>

                        <TiDelete onClick={() => {
                            setFormScreenBlocker("none")
                            document.body.style.overflowY = "auto"
                        }} className="closeOrderForm" />


                        <label htmlFor="name"> Имя </label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="email"> Email </label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="telefon"> Номер телефона </label>
                        <input type="tel" id="telefon" name="telefon" required />

                        <label htmlFor="adress"> Адрес </label>
                        <input type="text" id="adress" name="adress" required />

                        <textarea style={{ display: "none" }} name="message" defaultValue={table} />


                        <input className="sendButton" type="submit" value="Оформить заказ" onClick={
                            () => {
                                if (document.getElementById("name").value !== "" && document.getElementById("email").value !== "" && document.getElementById("telefon").value !== "" && document.getElementById("adress") !== "") {
                                    setFormScreenBlocker("none")
                                    document.body.style.overflowY = "auto"
                                    setSuccess("success")
                                    setTimeout(() => {
                                        setSuccess("none")
                                    }, 1000)

                                }
                            }
                        } />

                    </form>
                </div>


                <div className={success}>
                    <h2> Ваш заказ успешно оформлен </h2>
                </div>

                <Footer />




            </Wrapper>
        )

    }



}

export default ShopCard
