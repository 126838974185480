import Card from "../Card/Card"

import "./ContactIconTextDark.css"


const ContactIconTextDark = (props) => {
    return (
        <Card className = "contactIconTextRowDark" >
            <Card className="iconElipseDark">
                {props.contactIcon}
            </Card>
            <p className="contactTextDark"> {props.contactText} </p>
        </Card>
    )
}

export default ContactIconTextDark