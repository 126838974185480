import Card from "../../Components/Card/Card"
import ComforterText from "../../Components/ComforterText/ComforterText"
import Footer from "../../Components/Footer/Footer"
import Header from "../../Components/Header/Header"
import ImageComponent from "../../Components/ImageComponent/ImageComponent"
import Navigation from "../../Components/Navigation/Navigation"
import Subtitle from "../../Components/Subtitle/Subtitle"
import TextComponent from "../../Components/TextComponent/TextComponent"
import ViewMenuButton from "../../Components/ViewMenuButton/ViewMenuButton"
import Wrapper from "../../Components/Wrapper"

import { Carousel } from "react-responsive-carousel"

import "./HoReCa.css"

import { useRecoilState } from "recoil"
import { menuFilterState } from "../../Components/Atom"

const HoReCa = () => {

    /////////////////////////////////////////
    window.scrollTo(0, 0)
    ////////////////////////////////////////

    ///////// scroll bar on ///////////////
    document.body.style.overflowY = "auto"
    //////////////////////////////////////

    const [type, setType] = useRecoilState(menuFilterState)


    return (
        <Wrapper>

            <Navigation />

            <Header
                bgImage="./Assets/Images/hoReCaHeader.WEBP"
                headerText="Кондитерская Алекса"
                headerTitle="Сегмент HoReCa"
                headerLink="Смотреть меню"
                headerTo="/Menu"

                onClick={() => {
                    setType("torts")
                    sessionStorage.setItem("menuFilterKey", "torts")
                }}
            />

            <Card className="hoReCaMain" >
                <Card className="hoReCaMainColumn" >
                    <ComforterText> Кондитерская Алекса </ComforterText>
                    <Subtitle> Сегмент HoReCa </Subtitle>
                    <TextComponent> Кондитерская "Алекса" является профессиональным производителем тортов и пирожных, предоставляющем услуги сегмента HoReCa для ресторанов и кафе. Наши десерты представляют собой истинное воплощение роскоши, с искусным сочетанием изысканных и неповторимых вкусов. Мы используем такие необычные и интригующие ингредиенты, как, например, лаванда, фисташка и бобы тонка, чтобы оживить ваше воображение. </TextComponent>
                    <TextComponent> Оформление тортов и пирожных — это наш специальный акцент. Мы предлагаем вам велюровое или зеркальное покрытие, которые придают нашим изделиям роскошный вид. Кроме того, мы используем сухоцветы для элегантного дополнения. Наш стиль исключителен и выделяется на фоне конкуренции. </TextComponent>
                    <ViewMenuButton
                        onClick={() => {
                            setType("torts")
                            sessionStorage.setItem("menuFilterKey", "torts")
                        }}
                    > Смотреть меню </ViewMenuButton>
                </Card>
                <Card className="hoReCaMainColumn" >
                    <Carousel showArrows={true} >
                        <div>
                            <img src="./Assets/Images/slider7.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider8.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider3.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider4.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider5.WEBP" />
                        </div>
                        <div>
                            <img src="./Assets/Images/slider6.WEBP" />
                        </div>
                    </Carousel>
                </Card>
            </Card>

            <Card className="hoReCaMain" >
                <Card className="hoReCaMainColumn" >
                    <ImageComponent src="./Assets/Images/hoReCaImage.WEBP" />
                </Card>
                <Card className="hoReCaMainColumn" >
                    <ComforterText> Кондитерская Алекса </ComforterText>
                    <Subtitle> Почему выбирают нас </Subtitle>
                    <TextComponent> Мы постоянно экспериментируем с сочетаниями вкусов, чтобы создавать неповторимые десерты для Вас. Мы используем только натуральные и качественные ингредиенты, чтобы каждый кусочек наших тортов был ничем иным, как произведением искусства. А наши фотогеничные торты станут настоящей декорацией вашего праздника или особого момента в жизни. </TextComponent>
                    <TextComponent> Наша кондитерская “Алекса” постоянно развивается и расширяет ассортимент тортов и пирожных, чтобы удовлетворить потребности каждого из вас. </TextComponent>
                    <TextComponent> Однако, наше главное достоинство – это доступность. Мы полностью осознаем, что качество должно быть доступным, поэтому цены на наши десерты приятно Вас удивят. </TextComponent>
                    <ViewMenuButton
                        onClick={() => {
                            setType("torts")
                            sessionStorage.setItem("menuFilterKey", "torts")
                        }}
                    > Смотреть меню </ViewMenuButton>
                </Card>
            </Card>

            <Footer />

        </Wrapper>
    )
}

export default HoReCa