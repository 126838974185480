import "./MenuItem.css"

const MenuItem = (props) => {
    return (
        <div className = "menuItem" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <img className = "menuItemImage"  src={props.src} />
            <div className = "menuItemInfoCard">
                <p className = "menuItemName" > {props.menuItemName.toLowerCase()} </p>
                <p className="discription"> {props.menuItemDescription} </p>
                
                {props.children}

                <p className = "menuItemWeight" > Вес: {props.menuItemWeight} Гр. </p>

                <div className = "menuItemPriceCaard" >
                    <p className = "menuItemPrice" > Цена: {props.menuItemPrice} Руб. </p>
                    <button onClick = {props.onClick} className = "addToCardButton"> В корзину </button>
                </div>
            </div>


        </div>
    )
}

export default MenuItem