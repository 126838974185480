import Card from "../Card/Card"
import ImageComponent from "../ImageComponent/ImageComponent"


import { SlBag } from "react-icons/sl"
import { FiMenu } from "react-icons/fi"
import { IoClose } from "react-icons/io5"


import { Link } from "react-router-dom"


import { useState } from "react"


import "./Navigation.css"


import { useRecoilState } from "recoil"
import { shopCardItemsCountState } from "../Atom"


const Navigation = () => {


    const [screenBlocker,setScreenBlocker] = useState("offScreenBlocker")
    const [adaptiveNavigation,setAdaptiveNavigation] = useState("offAdaptiveNavigation")

    const adaptiveNavigationOpen = () => {

        setScreenBlocker("onScreenBlocker")
        setAdaptiveNavigation("onAdaptiveNavigation")

        document.body.style.overflowY = "hidden"
    }

    const adaptiveNavigationClose = () => {
        
        setAdaptiveNavigation("offAdaptiveNavigation")

        setTimeout ( () => {
            setScreenBlocker("offScreenBlocker")
            document.body.style.overflowY = "auto"
        },500)

    }

    
    const [shopCardItemsCount,setShopCardItemsCount] = useRecoilState(shopCardItemsCountState)




    return (
        <nav className="navigation">

            <Card className="navigationColumn">
                <Link to="/" className="navItem"> Главная </Link>
                <Link to="/Facturing" className="navItem"> Производство </Link>
                <Link to="/Menu" className="navItem"> Меню </Link>
                <Link to="/HoReCa" className="navItem"> HoReCa </Link>
            </Card>
            

            <Link to = "/" >
                <ImageComponent className = "logo" src = "./Assets/Icons/alexaLogo.png" />
            </Link>

            <Card className="navigationColumn">
                <Link to="/Contacts" className="navItem"> Контакты </Link>
                <Link to="/Delivery" className="navItem"> Сотрудничество и Доставка </Link>

                <Link to="/ShopCard" className="navItem navBagIconCard"> 
                    <SlBag className="navBagIcon"/>
                    <p className="navBagItemsCount"> {shopCardItemsCount} </p>
                </Link>
            </Card>

            {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <Card className = "adaptiveNavigation" > 
                <FiMenu
                    className = "burgerBar" 
                    onClick = {adaptiveNavigationOpen}
                />
                <Link to = "/ShopCard" className = "navItem navBagIconCard" > 
                    <SlBag className = "navBagIcon" />
                    <p className = "navBagItemsCount" > {shopCardItemsCount} </p>
                </Link>
            </Card>

            <Card 
                className = {screenBlocker}
                onClick = { (event) => {
                    if ( event.target.className === "onScreenBlocker") {
                        adaptiveNavigationClose()
                    }
                } }
            
            >
                <nav className = {adaptiveNavigation} >
                    <IoClose className = "closeNavigationBtn" onClick = {adaptiveNavigationClose}  />

                    <Card className = " adaptiveNavigationItemsCard" >
                        <Link to = "/" className = "adaptiveNavItem" > Главная </Link>
                        <Link to = "/Facturing" className = "adaptiveNavItem" > Производство </Link>
                        <Link to = "/Menu" className = "adaptiveNavItem" > Меню </Link>
                        <Link to = "/HoReCa" className = "adaptiveNavItem" > HoReCa </Link>
                        <Link to = "/Contacts" className = "adaptiveNavItem" > Контакты </Link>
                        <Link to = "/Delivery" className = "adaptiveNavItem" > Сотрудничество и Доставка </Link>
                    </Card>

                </nav>
            </Card>


        </nav>
    )
}

export default Navigation