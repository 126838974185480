import { Link } from "react-router-dom"
import TextComponent from "../TextComponent/TextComponent"
import "./Header.css"
import Card from "../Card/Card"

const Header = (props) => {
    return (
        <header className="header" style={ { backgroundImage: `url(${props.bgImage})` } } >
            <Card className="headerFilter">
                <p className="headerText"> {props.headerText} </p>
                <h1 className="headerTitle"> {props.headerTitle} </h1>
                <Link className="headerLink" to={props.headerTo} onClick = {props.onClick}> {props.headerLink} </Link>
            </Card>
        </header>
    )
}

export default Header