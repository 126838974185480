import Card from "../Card/Card"

import "./ContactIconText.css"


const ContactIconText = (props) => {
    return (
        <Card className = "contactIconTextRow" >
            <Card className="iconElipse">
                {props.contactIcon}
            </Card>
            <p className="contactText"> {props.contactText} </p>
        </Card>
    )
}

export default ContactIconText