import "./TextComponent.css"

const TextComponent = (props) => {
    return (
        <p className = "textComponent">
            { props.children }
        </p>
    )
}

export default TextComponent